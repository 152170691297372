//LIBRARIES
import React from "react";
//STYLEDCOMPONENTS
import * as S from "./style";
import { Divider } from "components/UI"
import Price from "./Price";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Header from "./Header";
import Feature from "./Feature";


function Plan(props) {
  const { plan, isMonthly } = props;
  const { t } = useTranslation();
  return (
    <S.BackContainer popular={plan.popular}>
      {plan.popular && <div className="popular">{t("Most Popular")}</div>}
      <S.Container popular={plan.popular} plancolor={plan.color}>
        <Header color={plan.color} planName={plan.name} width="116px" />
        <S.Description plancolor={plan.color}>{plan.description}</S.Description>
        <Price white={plan.color === "black"} prelabel={isMonthly ? plan.price.monthly.prelabel : plan.price.yearly.prelabel} label={isMonthly ? plan.price.monthly.label : plan.price.yearly.label}>{isMonthly ? plan.price.monthly.value : plan.price.yearly.value}</Price>
        <S.Interval plancolor={plan.color}>{!isMonthly && plan.name !== "Free" && <S.BilledYearly>{t('Billed yearly')}</S.BilledYearly>} </S.Interval>
        <div>
          <S.GetStartedButton plancolor={plan.color} onClick={() => window.location.href = plan.button.link}>
            {plan.button.text}
          </S.GetStartedButton>
        </div>
        <Divider />
        <div>
          <S.FeaturesContainer >
            {plan.features.map((feature, index) => (
              <Feature key={index}>{feature}</Feature>
            ))}
          </S.FeaturesContainer>
        </div>
      </S.Container>
    </S.BackContainer>
  );
}

export default Plan;
