import styled from "styled-components";
//import {BorderedDiv as BaseBorderedDiv, Divider as BaseDivider, Button as BaseButton} from 'components/UI';
import { Tabs as BaseTabs, Tab as BaseTab } from "@mui/material";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
 margin: 32px;
`;

export const YearlyLabel = styled.div`
  display: flex;
  align-items: center;
`;

export const DiscountLabel = styled.div`
  display: flex;
  align-items: center;
  padding: 0 7px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  white-space: nowrap;
  text-align: center;

  color: #927fff;
        > span {
          background: -webkit-linear-gradient(-70deg, #689afe 0%, #fc38ff 100%);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          -webkit-box-decoration-break: clone;
        } 
        
  > svg {
    height: 14px;
    margin-right: 4px;
  }
`;

export const Tab = styled(BaseTab).attrs({})`
  color: ${(props) => props.theme.colors.text.medium};



  &.MuiTab-root {
    min-width: 96px;
    min-height: 44px;
    text-transform: initial;
  


    &.Mui-selected {
      color: ${(props) => props.theme.colors.text.default};

      /* ${DiscountLabel} {
        color: #927fff;
        > span {
          background: -webkit-linear-gradient(-70deg, #689afe 0%, #fc38ff 100%);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          -webkit-box-decoration-break: clone;
        }
      } */
    }
  }
  .MuiTab-iconWrapper {
    width: 16px;
    transform: scaleX(-1);
  }
`;

export const Tabs = styled(BaseTabs).attrs({})`
  width: fit-content;

  &.MuiTabs-root {
    background-color: #e6e7eb;
    border-radius: 10px;
    min-height: 44px;
    position: relative;
    z-index: 0 !important;
  }

  .MuiTabs-flexContainer {
    display: inline-flex;
    position: relative;
    z-index: 1;
  }

  .MuiTabs-scroller {
    padding: 0px 0px;
  }

  .MuiTabs-indicator {
    top: 3px;
    bottom: 3px;
    right: 3px;
    height: auto;
    background: none;

    :after {
      content: "";
      display: block;
      position: absolute;
      top: 0px;
      left: 4px;
      right: 4px;
      bottom: 0px;
      border-radius: 8px;
      background-color: ${(props) => props.theme.colors.bg.default};
      background-color: white;

      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
    }
  }
`;
