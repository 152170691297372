//LIBRARIES
import React from 'react';
import styled from "styled-components";
import IconBase from 'icons';
import hexToRgba from 'hex-to-rgba';

export const FeatureContainer = styled.div`
    display: flex;
    padding: 10px 0px;
`;

export const Info = styled.div`
  width: 100%;
  font-size: ${(props) => props.theme.fonts.title.s.size};
  font-weight: ${(props) => props.theme.fonts.weight.medium};
  padding-left: 10px;
`;

export const Icon = styled(IconBase).attrs((props) => ({
size:"20px",
}))`
  width: 20px;
  height: 20px;
  color: ${(props) =>  props.theme.colors.text.success};
  filter: drop-shadow(-2px -2px 1px white) drop-shadow(2px 2px 1px rgba(0,0,0,0.1));
  filter: drop-shadow(0px 0px 1px white) drop-shadow(0px 0px 5px ${(props) => hexToRgba(props.theme.colors.text.success,0.3)});
`;



export default function Feature(props) {
  const {children } = props;
  return (
  <FeatureContainer>
    <Icon name="solid circle-check"/>
    <Info>{children}</Info>
  </FeatureContainer>
  );
}