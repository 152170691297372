import styled from "styled-components";
import { Typography } from "components/UI";

// export const Title = styled.div`
//   font-size: 60px;
//   line-height: 64px;
//   color: ${(props) => props.theme.colors.text.default};
//   font-weight: ${(props) => props.theme.fonts.weight.bold};
// `;

export const Container = styled.div` 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const MainContainer = styled.section` 

padding: 100px 0px;
display: flex;
justify-content: center;
align-items: center;

`;


export const Title = styled(Typography).attrs({
})`
  font-size: ${(props) => props.theme.fonts.title.xl.size};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile.max}px) {
    font-size: ${(props) => props.theme.fonts.title.xl.size};
  }
  margin-bottom: 16px;
  padding-right: 16px;
  padding-left: 16px;
  text-align: center;
`;




export const Subtitle = styled(Typography).attrs({
  size: "m" 
})`
  font-weight: ${(props) => props.theme.fonts.weight.bold};
`;

