//LIBRARIES
import React from "react";
//STYLEDCOMPONENTS
import * as S from "./style";
import Plan from "./Plan";
// import plans from "./plans.json";

function Plans(props) {
  const { isMonthly, plans} = props;

  return (
    <S.Container>
      <S.Row justify="space-between" spacing={2}>
        {plans.map((plan, index)=>
          <S.Col lg={3} md={3} sm={6} xs={12} key={index}>
            <Plan plan={plan} isMonthly={isMonthly} />
          </S.Col>
        )}
      </S.Row>
    </S.Container>
  );
}

export default Plans;
