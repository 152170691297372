import React from "react";
import * as o from "assets/svgs/complex/blurred-orbs";
import * as bg from "assets/svgs/complex/waves-background";
//STYLEDCOMPONENTS
import * as S from "./style";

const svgs = {
  red: {
    orbs: o.RedOrb,
    background: bg.WavesBackgroundRed,
  },
  purple: {
    orbs: o.PurpleOrb,
    background: bg.WavesBackgroundPurple,
  },
  green: {
    orbs: o.GreenOrb,
    background: bg.WavesBackgroundGreen,
  },
  black: {
    orbs: o.NeonOrb,
    background: bg.WavesBackgroundBlack
  },
};

function Header(props) {
  const { color, width, planName, ...others } = props;
  const Background = svgs[color] ? svgs[color].background : bg.WavesBackgroundPurple;
  const Orbs = svgs[color] ? svgs[color].orbs : o.PurpleOrb;

  return (
    <S.Container width={width} color={color}>
      <S.WavesBackground>
        <Background />
      </S.WavesBackground>
      <S.Wrapper>
        <S.TitleContainer>
          <S.Logo plancolor={color} />
          <div>{planName}</div>
          <S.Orb color={color ? color : "purple"} />
        </S.TitleContainer>
        <Orbs className="blurred-orbs" />
      </S.Wrapper>
    </S.Container>
  );
}

export default Header;
