import React from "react";
import * as S from "./style";
import { useTranslation } from "gatsby-plugin-react-i18next";
import MainLayout from "components/MainLayout";

// import { Container } from "components/UI/Common/LandingPage";

// const Partners = React.lazy(() => import('./Partners'));
// const Contact = React.lazy(() => import('./Contact'));

import Plans from "./Plans";
import PlansComparison from "./PlansComparison";
import IntervalTabs from "./IntervalTabs";

const Pricing = (props) => {
  const { t } = useTranslation();
  const [monthly, setMonthly] = React.useState(false);
  const content = t("pricing_section", { returnObjects: true });
  
  return (
    <MainLayout>
      <S.MainContainer>
        <S.Container>
          <S.Title>{content.title}</S.Title>
          <IntervalTabs setMonthly={setMonthly} />
          <Plans plans={content.plans} isMonthly={monthly} />
          <PlansComparison comparison={content.comparison} isMonthly={monthly} />
        </S.Container>
      </S.MainContainer>
    </MainLayout>
  );
};

export default Pricing;

