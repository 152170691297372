import styled from "styled-components";
//import {BorderedDiv as BaseBorderedDiv, Divider as BaseDivider, Button as BaseButton} from 'components/UI';
import { Grid } from "@mui/material";
import hexToRgba from "hex-to-rgba";
import IconBase from "icons";
import { Button as BaseButton, Typography } from "components/UI";

export const DefaultContainer = styled.div`
position: relative;
left: -200px;
padding-left: 200px;
margin-top: 50px;
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  color: #2d3748;
  font-weight: 700;
  color: ${(props) =>  props.theme.colors.text.darkPurple};
`;

export const Container = styled.div`
  width: 100%;
  position: relative;
  margin: 5em auto 30em;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile.max}px) {
    margin: 0px;
 }

  max-width: 1215px;
  overscroll-behavior: contain;
`;

export const MainTitle = styled(Typography).attrs({
  size: "xl"
  })`
text-align: center;
margin-top: 1.5em;
margin-bottom: 1em;
`;


export const MobilePlanContainer = styled.div`
padding-top: 72px;
`;


export const Icon = styled(IconBase).attrs((props) => ({
  size: props.name === "bold check" ? "0.9rem" : "0.8rem",
}))`
  color: ${(props) =>
    props.name === "bold check"
      ? props.theme.colors.text.success
      : props.theme.colors.text.error};
  filter: ${(props) =>
    props.name === "bold check"
      ? `drop-shadow(0 0 2px ${hexToRgba(
          props.theme.colors.text.success,
          0.4
        )})`
      : `drop-shadow(0 0 2px ${hexToRgba(
          props.theme.colors.text.error,
          0.4
        )})`};
`;

export const Button = styled(BaseButton).attrs((props) => ({
  variant: "contained",
}))`
padding:4px 8px;
font-size: 16px;
letter-spacing: -0.5px;
white-space: nowrap;

@media (max-width: ${(props) => props.theme.breakpoints.mobile.max}px) {
  margin-top: 20px;
  margin-bottom: 32px;
  padding:4px 16px;
  align-self: center;
 }

`;

export const SwitchPlans = styled.header`
  height: 100px;
  width: 50px;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
`;

export const Plan = styled(Title)`
  /* text-transform: uppercase; */
  font-size: 0.85rem;
  font-size: ${(props) => props.theme.fonts.title.m.size};
  color: #2d3748;
`;

export const Subheading = styled.p`
  font-weight: 600;
  padding: 20px 0;
  padding-bottom: 8px;
  color: #a0aec0;
  font-size: 1rem;
  font-size: ${(props) => props.theme.fonts.title.s.size};

  display: flex;
  height: 100%;
  align-items: center;
  text-align: left;
`;

export const BoldPoint = styled.p`
  color: #2d3748;
  font-weight: 600;
  font-size: ${(props) => props.theme.fonts.body.s.size};
  display: flex;
  height: 100%;
  align-items: center;
  text-align: left;
  
`;

export const Link = styled.a`
  color: blue;
  font-weight: 600;
  font-size: 1rem;
  font-size: ${(props) => props.theme.fonts.title.s.size};
  padding-bottom: 2px;
  border-bottom: 2px solid blue;
  cursor: pointer;

  &:hover {
    color: #2b6cb0;
    border-bottom: 2px solid #2b6cb0;
  }
  
`;

export const Price = styled(Plan)`
  font-size: 1.875rem;
  font-weight: 800;
  letter-spacing: -0.05rem;
`;

export const Small = styled.p`
  font-size: 1rem;
  padding-top: 8px;
`;




export const PriceColor = styled.div`
  width: 100%;

  /* height: calc(100% - 10px); */

  padding: 0px 6px;
  text-align: center;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;
  /* padding: 8px 0; */
  background-color: #e8ebf4;
  /* height: 36px; */

  min-height: 36px; 
  /* padding: 8px 0; */
 

    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.0174624),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0235573),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0282784),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.0339075),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.04317),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.07);

    /* border: 1px solid #e8ebf4; */
    border-left: 1px solid ${(props) => props.theme.colors.border.default};
    border-right: 1px solid ${(props) => props.theme.colors.border.default};

    box-shadow: 0px 26px 22px rgb(88 102 126 / 10%), 0px 2px 2px rgb(88 102 126 / 14%);


  ${(props) =>
    props.top
      && `
      padding-top: 1.5rem;
      margin-top: 1.25rem;
      border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem;
      justify-content: flex-start;
      height: 130px;
      border-top: 1px solid ${props.theme.colors.border.default};
      `}

${(props) =>
    props.topMobile
      && `
      border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem;
      min-height: 0px;
      height: 0px !important;
      display: block;

      border-top: 1px solid ${props.theme.colors.border.default};
      `}



  ${(props) =>
    props.bottom
      && `
      border-bottom-left-radius: 0.75rem;
      border-bottom-right-radius: 0.75rem;
      padding-bottom: 2rem;
      border-bottom: 1px solid ${props.theme.colors.border.default};

      height: 80px;
      `
   }


${(props) =>
    props.bottomMobile
      && `
      border-bottom-left-radius: 0.75rem;
      border-bottom-right-radius: 0.75rem;
      padding-bottom: 2rem;
      border-bottom: 1px solid ${props.theme.colors.border.default};

      `}


   flex-direction: column;
`;

export const Row = styled(Grid).attrs({
  direction: "row",
  container: true,
  justify: "space-between",
})`
  > * {
    ${PriceColor} {
      background-color: ${(props) => props.theme.colors.bg.default};
     
    }
  }
 
`;

export const Col = styled(Grid).attrs({
  item: true,
  container: true,
  direction: "column"
})`
  text-align: center;
  padding: 0px 6px;
`;
