//LIBRARIES
import React from "react";
import { returnComponent } from "./index"
//STYLEDCOMPONENTS
import * as S from "./style";

function PlansComparisonDefault(props) {
  const { plans, prices, categories, interval, buttons, ...other } = props;
  const ANCHOR = 2.25;
  const FIRST_COLUMN_ANCHOR = 3;

  return (
    <S.DefaultContainer>
      <S.Row justify="space-between">
        <S.Col xs={FIRST_COLUMN_ANCHOR}/>
        {plans.map((plan, index) => (
          <S.Col xs={ANCHOR} key={index}>
            <S.Title>{plan.name}</S.Title>
            <S.Small>{plan.description}</S.Small>
          </S.Col>
        ))}
      </S.Row>
      <S.Row justify="space-between">
        <S.Col xs={FIRST_COLUMN_ANCHOR}></S.Col>
        {prices.map((price, index) => (
          <S.Col xs={ANCHOR} key={index}>
            <S.PriceColor top>
              <S.Price>
                {interval === "monthly" ? price.monthly : price.yearly}
              </S.Price>
              <S.Small pt={2}>{price.description}</S.Small>
            </S.PriceColor>
          </S.Col>
        ))}
      </S.Row>
      <S.Row justify="space-between">
        <S.Col xs={FIRST_COLUMN_ANCHOR}>
        </S.Col>
        {buttons.map((button, index) => (
          <S.Col xs={ANCHOR} key={index}>
            <S.PriceColor>
              <S.Button color={button.color} onClick={() => window.location.href = button.link}>{button.text}</S.Button>
            </S.PriceColor>
          </S.Col>
        ))}
      </S.Row>

      {categories.map((category, i) => {
        return (
          <div key={i}>
            <S.Row justify="space-between">
              <S.Col xs={FIRST_COLUMN_ANCHOR}>
                <S.Subheading>{category.name}</S.Subheading>
              </S.Col>
              <S.Col xs={ANCHOR}>
                <S.PriceColor />
              </S.Col>
              <S.Col xs={ANCHOR}>
                <S.PriceColor />
              </S.Col>
              <S.Col xs={ANCHOR}>
                <S.PriceColor />
              </S.Col>
              <S.Col xs={ANCHOR}>
                <S.PriceColor />
              </S.Col>
            </S.Row>
            {category.features.map((feature, j) => {
              return (
                <S.Row justify="space-between" key={j}>
                  <S.Col xs={FIRST_COLUMN_ANCHOR}>
                    <S.BoldPoint>{feature.name}</S.BoldPoint>
                  </S.Col>
                  {feature.values.map((value, k) => (
                    <S.Col xs={ANCHOR} key={k}>
                      <S.PriceColor>{returnComponent(value)}</S.PriceColor>
                    </S.Col>
                  ))}
                </S.Row>
              );
            })}
          </div>
        );
      })}
      <S.Row justify="space-between">
        <S.Col xs={FIRST_COLUMN_ANCHOR}>
        </S.Col>
        {buttons.map((button, index) => (
          <S.Col xs={ANCHOR} key={index}>
            <S.PriceColor bottom>
              <S.Button color={button.color} onClick={() => window.location.href = button.link}>{button.text}</S.Button>
            </S.PriceColor>
          </S.Col>
        ))}
      </S.Row>
    </S.DefaultContainer>
  );
}

export default PlansComparisonDefault;
