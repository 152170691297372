//LIBRARIES
import React from "react";

//STYLEDCOMPONENTS
import * as S from "./style";
import Icon from "icons"
import { useTranslation } from "gatsby-plugin-react-i18next";

function IntervalTabs(props) {
  const { setMonthly, ...other } = props;
  const [tabIndex, setTabIndex] = React.useState(1);
  const { t } = useTranslation();

  function handleTabsChange(e, index) {
    index === 0 ? setMonthly(true) : setMonthly(false);
    setTabIndex(index);
  }

  return (
    <S.Container>
   <S.Tabs
      value={tabIndex}
      onChange={handleTabsChange}
    >
      <S.Tab disableRipple label={t('monthly').trim().replace(/^\w/, (c) => c.toUpperCase())} />
      <S.Tab disableRipple 
      label={<S.YearlyLabel>{t('yearly').trim().replace(/^\w/, (c) => c.toUpperCase())} <S.DiscountLabel><Icon name="classification"/><span>{t('Get 2 months free')}</span></S.DiscountLabel></S.YearlyLabel>}/>
    </S.Tabs>
    </S.Container>
  );
}

export default IntervalTabs;
