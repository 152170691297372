import styled from "styled-components";
import { Orb as BaseOrb } from "components/UI";
import BaseIcon from "icons";
import { keyframes } from "styled-components";

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Logo = styled(BaseIcon).attrs({
  name: "theos",
})`

  && {
    width: 80px;
    /* filter: drop-shadow(0 0 1px black);
    ${(props) =>
    props.plancolor === "black" &&
    `
    color:  white;
    filter: drop-shadow(0px 0px 1px white);
  `}*/
  }
`;

export const TitleContainer = styled.div`
  font-size: ${(props) => props.theme.fonts.title.l.size};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  width: min-content;
  height: 100%;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translateX(26%);
`;

export const Container = styled.div`
  width: 100%;
  height: ${(props) => (props.width ? props.width : "140px")};
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: inherit;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;

  svg {
  transform: unset !important;
}
  
  ${(props) =>
    props.color === "black" &&
    `
    color:  white;
  `}

box-shadow: inset 1px 1px 3px 1px rgba(255, 255, 255, 0.2);
`;

export const BlurredOrbs = styled.div``;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  max-width: 300px;
  position: relative;
  position: relative;
  /*   */
  display: flex;
  justify-content: flex-start;
  align-items: center;


  > .blurred-orbs {
    position: absolute;
    height: 150% !important;
    left: -55%;
    animation-name: ${rotate};
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    filter: saturate(2) blur(10px);
  }
`;

export const WavesBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: 0%;


  
`;

export const Orb = styled(BaseOrb)`
  position: absolute;
  left: 105%;
`;
