//LIBRARIES
import React from "react";
import styled from "styled-components";

import { Typography } from "components/UI";

export const PriceContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  > div {
    display: flex;
  }

 
`;

export const Type = styled.div`
  flex: 1;
  display: flex;
  font-size: ${(props) => props.theme.fonts.title.s.size};
  font-weight: ${(props) => props.theme.fonts.weight.medium};
  color: ${(props) => props.theme.colors.text.medium};
  align-items: flex-end;
  padding-bottom: 10px;
  margin-left:4px;
  text-align: left !important;
  white-space: nowrap;
`;

export const PriceNumber = styled(Typography).attrs((props) => ({
  fontSize: "48px",
  weight: "medium",
}))`
  line-height: 1.2;

  ${(props) =>
    props.isCustom &&
    `
    color: white;
  `}

`;

export const Symbol = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
  font-size: ${(props) => props.theme.fonts.title.s.size};
  font-weight: ${(props) => props.theme.fonts.weight.medium};
  color: ${(props) => props.theme.colors.text.medium};

`;

export default function Price(props) {
  const { children,  label, prelabel } = props;
  const isCustom = children.toString().toLowerCase() === "custom" || children.toString().toLowerCase() === "especial";

  return (
    <PriceContainer>
      <div>
        {!isCustom && <Symbol>$</Symbol>}
        <PriceNumber isCustom={isCustom}>{children}</PriceNumber>
        {!isCustom && prelabel && <Type>{prelabel}</Type>}
        {!isCustom && <Type>/ {label}</Type>}
      </div>
    </PriceContainer>
  );
}
