import styled from "styled-components";
//import {BorderedDiv as BaseBorderedDiv, Divider as BaseDivider, Button as BaseButton} from 'components/UI';
import { Grid } from "@mui/material";

export const Container = styled.div`
width:100%;
padding: 0px 16px;
`;

export const Row = styled(Grid).attrs(({
  container: true,
  justifyContent: "center",
  alignItems: "top",
  direction: "row"
}))`   
  `;

export const Col = styled(Grid).attrs(({
  item: true,
}))`
`;