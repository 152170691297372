//LIBRARIES
import React from "react";
import { returnComponent } from "./index";
import {Divider} from "components/UI"

//STYLEDCOMPONENTS
import * as S from "./style";

function PlansComparisonMobile(props) {
  const { plans, prices, categories, interval, buttons } = props;

  const ANCHOR = 4;
  const FIRST_COLUMN_ANCHOR = 8;

  return (
    <>
      {plans.map((plan, index) => (
        <S.MobilePlanContainer key={index}>
          <S.Row justify="space-between" key={index}>
            <S.Col xs={12}>
            </S.Col>
            <S.Col xs={12}>
              <S.Title>{plan.name}</S.Title>
              <S.Small>{plan.description}</S.Small>
            </S.Col>
          </S.Row>
          <Divider height={32}/>
          <S.Row justify="space-between">
            <S.Col xs={12}></S.Col>
            <S.Col xs={12} key={index}>
                <S.Price>
                  {interval === "monthly"
                    ? prices[index].monthly
                    : prices[index].yearly}
                </S.Price>
                <S.Small pt={2}>{prices[index].description}</S.Small>
        
            </S.Col>
          </S.Row>
          <S.Row justifyContent="center">
            <S.Col xs={9}>
              {/* <S.PriceColor> */}
                <S.Button color={buttons[index].color} onClick={() => window.location.href = buttons[index].link}>
                  {buttons[index].text}
                </S.Button>
              {/* </S.PriceColor> */}
            </S.Col>
          </S.Row>

          {categories.map((category, i) => {
            return (
              <div key={i}>
         
                <S.Row justify="space-between">
                  <S.Col xs={FIRST_COLUMN_ANCHOR}>
                    <S.Subheading>{category.name}</S.Subheading>
                  </S.Col>
                  <S.Col xs={ANCHOR}>
                  <S.PriceColor topMobile={i===0}/>
                  </S.Col>
                </S.Row>
          
                {category.features.map((feature, j) => {
                  return (
                    <S.Row justify="space-between" key={j}>
                      <S.Col xs={FIRST_COLUMN_ANCHOR}>
                        <S.BoldPoint>{feature.name}</S.BoldPoint>
                      </S.Col>
                      {/* {feature.values.map((value, k) => (
                    <S.Col xs={ANCHOR} key={k}>
                      <S.PriceColor>{returnComponent(value)}</S.PriceColor>
                    </S.Col>
                  ))} */}
                      <S.Col xs={ANCHOR}>
                        <S.PriceColor>
                          {returnComponent(feature.values[index])}
                        </S.PriceColor>
                      </S.Col>
                    </S.Row>
                  );
                })}
              </div>
            );
          })}

          <S.Row justify="space-between">
            <S.Col xs={FIRST_COLUMN_ANCHOR}></S.Col>
            <S.Col xs={ANCHOR}>
              <S.PriceColor bottomMobile>
                {/* <S.Button color={buttons[index].color}>
                  {buttons[index].text}
                </S.Button> */}
              </S.PriceColor>
            </S.Col>
          </S.Row>


        </S.MobilePlanContainer>
      ))}
    </>
  );
}

export default PlansComparisonMobile;
