import styled from "styled-components";
import hexToRgba from "hex-to-rgba";
import { Button, Text } from "components/UI";

const boxShadowColor = "#7a83ff";

export const BackContainer = styled.div`
  position: relative;
  min-height: 835px;
  height: 100%;
  width: 100%;
  padding: 32px 8px 8px 8px;
  border-radius: 24px;

  ${(props) =>
    props.popular &&
    `
    background: linear-gradient(
    90deg,
    #7a83ff,
    #8382ff,
    #8c80ff,
    #947fff,
    #9d7dff,
    #a57cff,
    #ad7aff
  );
  `}

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${(props) =>
    props.popular &&
    `
   box-shadow: inset 0 7em 10em -5em ${hexToRgba(boxShadowColor, 0.6)},
    0 0.3em 0.5em -0.2em ${hexToRgba(boxShadowColor, 1)},
    0 1em 2em -0.75em ${hexToRgba(boxShadowColor, 0.75)},
    0 1em 3em -0.5em ${hexToRgba(boxShadowColor, 0.5)},
    0 3em 3em -0.25em ${hexToRgba(boxShadowColor, 0.2)};
  `}

  background-size: 150% 150%;

  .popular {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    color: ${(props) => props.theme.colors.text.default};
    color: white;
    font-size: ${(props) => props.theme.fonts.title.s.size};
    font-weight: ${(props) => props.theme.fonts.weight.bold};
    text-transform: uppercase;
    margin-bottom: 10;
    top: 6px;
  }
`;

export const Description = styled(Text).attrs({})`
  text-align: center;
  line-height: 1.25;
  min-height: 40px;
  padding: 0px 16px;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  font-size: 1.15rem;
  font-weight: ${(props) => props.theme.fonts.weight.medium};

  ${(props) =>
    props.plancolor === "black" &&
    `
    color: white;
  `}
`;

export const Interval = styled.div`

  position: relative;
  color: ${(props) => props.theme.colors.text.medium};

  width:100% !important;
  height: 20px !important;

  

 div {
  left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  position: absolute;
  margin-top: -8px;

  ${(props) =>
    props.plancolor === "black" &&
    `
    color: white;
  `}
 }
`;


export const BilledYearly = styled(Text)`

left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  position: absolute;
  margin-top: -8px;

  ${(props) =>
    props.plancolor === "black" &&
    `
    color: white;
  `}
`;

export const Container = styled.div`
  height: 100%;
  /* padding: 20px; */
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: end;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  position: relative;
  /* padding-bottom: 32px; */

  background-color: ${(props) => props.theme.colors.bg.default};
  /* background-color: #212C38; */

  /* background-color: green; */
  width: 100%;

  /* border: 1px solid ${(props) => props.theme.colors.border.default}; */

  ${(props) =>
    !props.popular &&
    `
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.0174624),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0235573),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0282784),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.0339075),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.04317),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.07);
  `}

  /* box-shadow: 0px 100px 80px ${hexToRgba("#87E5F9", 0.0174624)},
    0px 41.7776px 33.4221px ${hexToRgba("#87E5F9", 0.0235573)},
    0px 22.3363px 17.869px ${hexToRgba("#87E5F9", 0.0282784)},
    0px 12.5216px 10.0172px ${hexToRgba("#87E5F9", 0.0339075)},
    0px 6.6501px 5.32008px ${hexToRgba("#87E5F9", 0.04317)},
    0px 2.76726px 2.21381px ${hexToRgba("#87E5F9", 0.07)}; */
    /* #87E5F9 */


    ${(props) =>
    props.plancolor === "black" &&
    `
    background-color: #1A1D39;
    color: white;
  `}
`;

export const GetStartedButton = styled(Button).attrs((props) => ({
  color: props.plancolor === "black" ? "secondary":"primary",
  variant: props.plancolor !== "black" ? "contained": undefined
}))`
  padding: 8px 16px;
  font-size: ${(props) => props.theme.fonts.title.s.size};
`;

export const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  padding-top: 8px;
  padding-bottom: 32px;
  padding-left: 40px;
  padding-right: 40px;
`;
