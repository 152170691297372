import * as React from "react";
import Pricing from "components/landing-page/Pricing";
// import ThemeProvider from "./ThemeProvider";
// markup
import { graphql } from 'gatsby'

const PricingPage = ({}) => {
  return <Pricing/>
};

export default PricingPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
