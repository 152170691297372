//LIBRARIES
import React from "react";
// import pricing from "./plans-compare.json";
import { Default, Mobile } from "components/UI/devices";
import PlansComparisonDefault from "./PlansComparisonDefault";
import PlansComparisonMobile from "./PlansComparisonMobile";

//STYLEDCOMPONENTS
import * as S from "./style";

export function returnComponent(value) {
  if (value)
    if (value !== true)
      return <S.BoldPoint>{value}</S.BoldPoint>;
    else return <S.Icon name={"bold check"} />;
  return <S.Icon name={"bold close"} />;
}

function PlansComparison(props) {
  const { isMonthly, comparison } = props;

  let interval = isMonthly ? "monthly" : "yearly";
  const plans = [
    {
      id: comparison.plans.ids[0],
      name: comparison.plans.names[0],
      description: comparison.plans.descriptions[0],
    },
    {
      id: comparison.plans.ids[1],
      name: comparison.plans.names[1],
      description: comparison.plans.descriptions[1],
    },
    {
      id: comparison.plans.ids[2],
      name: comparison.plans.names[2],
      description: comparison.plans.descriptions[2],
    },
    {
      id: comparison.plans.ids[3],
      name: comparison.plans.names[3],
      description: comparison.plans.descriptions[3],
    }
  ];

  const buttons = comparison.plans.buttons;
  const prices = comparison.plans.prices;
  const categories = comparison.plans.categories;

  return (
    <S.Container id="compare-plans">
      <S.MainTitle style={{marginBottom: "0px"}}>{comparison.title}</S.MainTitle>
      <Default>
        <PlansComparisonDefault plans={plans} prices={prices} categories={categories} interval={interval} buttons={buttons} />
      </Default>
      <Mobile>
        <PlansComparisonMobile plans={plans} prices={prices} categories={categories} interval={interval} buttons={buttons} />
      </Mobile>
    </S.Container>

  );
}

export default PlansComparison;
